import React from 'react'
import { graphql } from 'gatsby'
import QRCode from 'qrcode.react'
import { MinimalLayout } from 'screens/shared/Layout'
import { Box } from 'components/primitives'
import Head from 'components/modules/Head'
import Heading from 'components/core/Heading'

const ProductQR = ({
  data: {
    product: { data },
    series: { edges }
  },
  ...props
}) => {
  return (
    <MinimalLayout display='flex'>
      <Head title={`QR: ${data.name}`} />
      <Box
        width={400}
        m='auto'
        display='flex'
        flexDirection='column'
        alignItems='center'
      >
        <Heading mb={1}>{data.name}</Heading>
        <Box>
          <QRCode
            id={data.handle}
            value={`https://tmtcollective.com/products/${data.handle}?ref=qr`}
          />
        </Box>
      </Box>
    </MinimalLayout>
  )
}

export default ProductQR

export const productQuery = graphql`
  query ProductQR($handle: String!, $series: String!) {
    product: airtable(
      table: { eq: "제품" }
      data: { handle: { eq: $handle } }
    ) {
      data {
        name
        type
        tile_type
        colors
        tags
        surface
        width
        length
        derived_size
        coverage
        derived_coverage
        price_per_coverage
        price_per_coverage_without_tax
        price_per_box
        pieces_per_box
        has_samples
        series
        series_ko
        series_variant
        handle
        handle_ko
        description_series
        description
        video_url
        shop_url
        created_at
        updated_at
      }
    }
    series: allAirtable(
      filter: { table: { eq: "제품" }, data: { series: { eq: $series } } }
    ) {
      edges {
        node {
          data {
            name
            type
            tile_type
            colors
            tags
            surface
            width
            length
            derived_size
            coverage
            derived_coverage
            price_per_coverage
            price_per_coverage_without_tax
            price_per_box
            pieces_per_box
            has_samples
            series
            series_ko
            series_variant
            handle
            handle_ko
            description_series
            description
            video_url
            shop_url
            created_at
            updated_at
          }
        }
      }
    }
  }
`
